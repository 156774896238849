/* App.css */
.InvestApp {
    padding: 70px 0;
    font-family: 'Arial', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .swap-container {
    /* width: 400px; */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    background: #f1f5f9;
}
  
  .tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  
  .tab {
    padding: 10px;
    cursor: pointer;
  }
  
  .tab.active {
    border-bottom: 2px solid blue;
  }
  
  .input-group {
    text-align: left;
    align-items: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    background: white;
    padding: 15px;
    border-radius: 10px;
}
  .input-group label {
    margin-bottom: 5px;
    display: block;
  }
  
  .input-group input  {
    width: 100px;
    padding: 10px;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 30px;
    border: 0;
    font-size: x-large;
}
.input-group input:focus{
    outline: none;
}

.input-group select:focus{
    outline: none;
}

.align-content-center{
  align-content: center;
}

.no-margin{
  margin: 0;
}
.balance-p {
  font-size: 13px;
  font-weight: 600;
}

.flex-column{
  flex-direction: column !important ;
}

.coin-label{
  padding: 12px;
  border: 1px solid beige;
  background: #ebf2f2;
  align-content: center;
  display: flex;
  max-height: 20px;
  border-radius: 50px;
  border-radius: 15px;
}

.input-group input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    /* ... other styles ... */
  }
 .radio-option{
  margin:3px;
 }

  .input-group select {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    color: white;
    padding: 5px;
    margin: 15px 0;
    border: 0px;
    font-size: large;
    background: var(--primary-color);
}
  
  .invest-button-app {
    width: 100%;
    padding: 10px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .invest-button-app:disabled {
    background-color: #ccc; /* Dimmer background color */
    color: #666; /* Dimmer text color */
    cursor: not-allowed; /* Cursor to indicate that the button is not clickable */
    border: 1px solid #999; /* Optional: Change the border color */
}
  
  .info {
    margin-top: 20px;
    font-size: 0.8em;
  }

  .coin-row{
    display: flex;
    flex-direction: row;
    justify-content: end;
    background: white;
    margin-bottom: 8px;
    border-radius: 8px;
}

  .width-30{
    width: 30%;
    
}
  
  .redeem-btn{
    align-content: center;
    background-color: var(--primary-color);
    color: white;
    padding: 5px 10px;
    border: 1px solid var(--primary-color);
    
  }

  td{
    padding: 15px;
    text-align: left;
    margin: 0 5px;
}
