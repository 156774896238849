.slide-img {
    width: 300px;
    
  }
  
.slide-button {
    padding: 10px;
    margin: 5px;
    font-size: 16px;
  }
  
.slide-wrapper{
    display: flex;
    flex-direction: row;
    height:100vh;
    padding: 40px 0;
    max-width: 350px;
    justify-content: center;
}

.hiw{
    margin: 0px;
    color: red;
}
.hwi-label{
    color: var(--primary-color);
}

.slide-nav{
    position: relative;
    top:50%;
}

.login-div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.app-login-pw{
    margin-bottom: 10px;
    margin-top: 50%;
    position: relative;
    width: 100%;
    border: 2px solid grey;
}

.app-login-btn{
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}
.app-login-div{
    height: 100vh;
    margin: 10%;
}

.hwi-btn{
    display: none;
    text-align: center;
}
.mobile-bg-image{
    /* background-image: url('/mobile-blue.png'); */
}

@media (max-width: 768px) {
    .login-div{
        flex-direction: column-reverse;
    }
    .slide-wrapper{
        max-width: 90%;
        width: 90%;
    }
    .app-login-pw,.app-login-btn{
        width: 90%;
    }
    .slide-img-div {
        padding: 0 10%;
    }
    
    .slide-nav{
        display: none;
    }
    .hwi-btn{
        display: block;
    }

}
