.howitworks{
    /* background-color: #f2f8fe; */
    /* background-color: #18222c; */
    max-width: 100%;
    overflow: hidden;
    color: white;
    text-align: center;
    padding: 140px 0px 140px 0px;

}

.image-container {
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 20px; /* Adjust as needed */
    flex-shrink: 0; /* Prevent the image from shrinking */
  }

.hiw-icon{
    color: var(--rabbitcha-gold);
    font-size: x-large;
}

.no-padding{
    padding: 5px;
    margin: 0px;
}

.how-it-works-point{
    font-size: larger;
}

.purple{
    color: var(--rabbitcha-gold) !important;
}

.how-wrapper {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column; /* Default to stacking vertically */
    justify-content: center;
    text-align: left;
   
}

.how-wrapper:focus-{
    animation: slideInFromBottom 0.5s ease-out forwards;
}

.how-wrapper ul>li {
    padding-bottom: 15px;
}

.rotation-image{
    -webkit-animation: rotation 15s infinite linear;
    animation: rotation 15s infinite linear;
    padding: 0 10%;
    width: 350px;
}

@keyframes rotation{
    0%{
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
    }

}

@media (min-width: 768px) { /* Adjust this threshold as needed */
    .how-wrapper {
        flex-direction: row;
        gap: 100px;
    }
    .howitworks-desc{
        max-width:45%;
    }
    
  }

  @media (max-width: 768px) { /* Adjust this threshold as needed */
    .howitworks{
        padding: 25px;
        
        
    }

    
    .how-wrapper {
        margin-top: 50px;
        gap: 30px;
    }
    .rotation-image{
        width: 320px;
    }
  }