/* FAQComponent.css */
.faq-container {
    width: 100%;
    padding: 2rem;
    background-color: #061523;
    box-sizing: border-box;
  }

.faq-item{
  border-radius: 5px;
  padding: 15px;
  background-color: #081e32;
  color: #868d94;
  font-weight: 400;
  border: 1px solid #10365e;
}
.faq-item-open{
    color: white;
    background-color: var(--primary-color);
}
  
  .faq-header{
    text-align: center;
    margin-bottom: 0.5rem;
  }
  
  .faq-header p {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .faq-content{
    padding: 0 10%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 30px;
}
  .faq-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }
  
  .nav-item {
    border: none;
    background: none;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    cursor: pointer;
    border-bottom: 3px solid transparent;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }
  
  .nav-item.active {
    border-bottom: 3px solid #007bff;
    
  }
  
  /* .faq-content .faq-item {
    margin-bottom: 1rem;
  } */
  
  .faq-content h3 {
    margin-bottom: 0.5rem;
    font-weight: 500;
}
  
  .faq-content{
    text-align: justify;
    color: #333;
  }

  @media (max-width: 768px)  {
    .faq-content{
        padding:0px;
    }
    
  }
  