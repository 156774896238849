/* components/InfoDiv1.css */
.AboutUs {
    width: 100%;
    height: 100vh;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.team-member{
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;
}

.team-member-photo{
  border-radius: 5px;
}

.team-member-photo img{
  height: 200px;
  width: 200px;
  border-radius: 20px;
  margin: 1px;
  border: 5px solid #10365e;
}

.team-member-description{
  padding: 0 30px;
  width: 40%;
}
.landing-img{
  width:100%
}

.container-wrapper{
  display:flex;  
  background: #081e32;
  padding: 30px;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .team-member{
    flex-direction: column;
  }
  .team-member-description {
    padding: 0;
    width: unset;
  }
  .container-wrapper{
    flex-direction: column;
  }
  .landing-img{
    height: 100vh;
  }
  
}