.product-description{
    padding: 140px 10%;
    font-weight: 500;
}

.weight-500{
    font-weight: 400;
}

.prod-invest-btn{
    width: 150px;
    border-radius: 5px;
    height: 40px;
    font-size: larger;
    background: var(--rabbitcha-gold);
    border: 1px solid var(--rabbitcha-gold);
    color: var(--primary-color);
}
.charts-stat-wrapper{
    height: fit-content;
}

.status-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 350px;
    margin: 20px;
    padding: 15px;
    border: 1px solid #e0d8d8;
    height: fit-content;
}

.charts-stat-wrapper{
    display: flex;
    flex-direction: row;
    padding: 10%;
}

.card-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;

}

.card-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 16px;

}
.header{
    font-size: xx-large;
    font-weight: 500;
}

.card-label {
    /* Styling for label */
    color:black
}

.card-value {
    /* Styling for value */
    color: #333;
}

#myChart{
    display: initial !important;
    border: 1px solid #e0d8d8;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 4px 8px #0002;
    
}

@media (max-width: 768px) {
    .charts-stat-wrapper{
        flex-direction: column;

    }
    #myChart{
        height: 250px !important;
    }
    .status-card {
        width: 250px;

    }

    
}

@media (min-width: 768px) {
    #myChart{
        height: 400px !important;
        width: 700px !important;
}

    
}