@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

:root {
  --header-color: #182b3d; 
  --primary-color: #081b2e;  
  --rabbitcha-gold: #eacf69 ;
  --secondary-color: #50e3c2;
  --background-color: #f0f0f0;
  --text-color: #333;
  --error-color: #ff1744;
}

.App {
  max-width: 100%;
  overflow: hidden;
  color:white;
  background-color: var(--primary-color);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a{
  cursor: grab;

}

.icon-wrapper{
  display: flex;
  flex-direction: row;
}
.social-mdeia-icon {
  /* display: inline; */
  font-size: 30px;
  padding: 5px 25px 5px 2px;
}

.nav-link {
  color: white;
  text-decoration: none;
}

.no-text-decoration{
  text-decoration: none;
  color: inherit;
}

.display-flex{
  display: flex;
}

.footer-wrap{
  width: 80%;
  /* height: 40vh; */
  /* border: 1px solid; */
  border-radius: 30px;
  padding: 40px;
  color: white;
  display: flex;
  justify-content: space-evenly;
  background: #081e32;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--primary-color);
}

.weight-400{
  font-weight: 400;
}

.app-login-div{
  top: 30%;
  left: 30%;
}

.app-login-pw{
  height: 30px;
  border-radius: 5px;
}

.app-login-btn{
  height: 35px;
}
.header-button {
  background-color: var(--primary-color);
  min-width: 150px;
  border-radius: 4px;
  padding: 10px;
  color: white;
  border: 2px solid var(--primary-color);
  font-size: 20px;
  font-weight: 500;
}

.tokenomics{
  font-size: xx-large;
  padding: 20px;
  display: block;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%); /* Start below the view */
    opacity: 0;  /* Start transparent if desired */
  }
  to {
    transform: translateY(0);
    opacity: 1;  /* End fully opaque if fading in */
  }
}

@media (max-width: 768px) {

  .footer-wrap{
    flex-direction: column;
  }

}
