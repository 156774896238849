/* components/BundleContainer.css */
.bundleContainer{
  background-color: #061523;
  max-width: 100%;
  overflow: hidden;
  padding: 140px 0;
  text-align: center;
  width: 100%;
}

.bundle-desc{
  color: #868d94;
}
.bundleTile {
  width: calc(33.333% - 20px); /* Original width for larger screens */
  max-height: 200px;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.bundleTile:hover {
  transform: scale(1.05);
}

.no-text-decoration{
  text-decoration: none;
}

.bundleWrapper{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 100%;
  justify-content: space-evenly;
  padding: 40px 100px;
  gap: 40px;
}

.bundle-anchor{
  transition: transform 0.1s ease;
  
}

.bundle{
  text-decoration: none;
  min-width: 300px;
  max-width: 350px;
  border: 1px solid grey;
  
}

/* Bundle.css */
.bundle{
  background-color: #081e32;
  border: 2px solid #10365e;
  border-radius: 20px;
  color: white;
  max-width: 350px;
  min-height: 270px;
  min-width: 300px;
  height:400px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
}

.bundle:hover .buy-button {
  opacity: 1;
  transform: translateY(0);
  z-index: 1000;
}

.bundle-anchor:hover {
  transform: scale(1.1); /* Reduce size to 95% of the original on hover */
}

.bundle-logo{
  padding: 20px 0px 10px 0px;
}

.bundle-h1{
  color: white;
  text-align: left;
}

.bundle-desc{
  padding: 10px 20px;
}

.read-more{
  text-align: left;
  color: #7db1f8;
}

.bundle-name{
  display: block;
  text-align: left;
  color: #83b7ff;
  /* background-color: #121212; */
  padding: 10px 20px;
}

.bundle-description{
  text-align: left;
}

.bundle-header{
  color: white;
 
}

.tag-lg {
  margin: 7px;
  display: inline-block;
  font-family: Graphik Web Medium, sans-serif;
  font-size: 14px;
  line-height: 1;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}


.buy-button {
  position: absolute;
  bottom: 20px;
  left: 30%;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.3s, transform 0.3s;
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.bundleTable{
  margin: 10px 0px;
  position: absolute;
  bottom: 0px;
  width: -webkit-fill-available;
}

.bundleTableRow {
  display: flex;
  justify-content: space-around;
  color:#ffffff ;
  
  
}

.bundleValueRow {
  display: flex;
  justify-content: space-around;
  color: #7878a2;
}


.bundleTableCell {
  flex: 1;
  padding: 5px 10px;
  text-align: center;
}





/* Media query for small screens */
@media (max-width: 768px) {
  .bundleTile {
    width: 100%; /* Make bundle tiles full width on small screens */
  }

  .bundleWrapper{
    padding: 40px 20px;
  }
}
