/* components/HeaderBar.css */
.HeaderBar{
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 999;
  display: flex;
  justify-content: space-around;
  padding: 10px 20px;
  /* background-color: var(--header-color); */
  font-size: larger;
  font-weight: 500;
  transition: background-color 0.3s;
}

.scrolled {
  background-color: var(--header-color);
  box-shadow: 0px 0px 10px 1px rgb(0, 0, 0, 0.2);
}

.transition, .btn, .form-control, a, input, button {
  transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
}


.header-button {
  /* background-color: var(--rabbitcha-gold); */
  background-color: transparent;
  min-width: 150px;
  border-radius: 4px;
  padding: 10px;
  color: var(--rabbitcha-gold);
  border: 2px solid var(--rabbitcha-gold);
  font-size: 20px;
  font-weight: 500;
}

#redeem{
  color: #da4bfd;
  background: white;
}

.invest-button{
  background-color: white;
  color: #2685ef;
}
  
  .logo {
    height:30px;
  }
  
  .navigation {
    display: flex;
    align-items: center;
    gap: 45px;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
  }
  
  @media (max-width: 768px) {
    .navigation {
      display: none;
      width: 100%;
      /* gap: 50px; */
      flex-direction: column; /* Stack links vertically */
      position: absolute;
      text-align: center;
      right: 0;
      top: 70px; /* Adjust based on your header height */
      background-color: #081e32; /* Adjust based on your preference */
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 10px 1px rgb(0, 0, 0, 0.2);
    }

    .header-button{
      width: 100%;
    }
  
    /* Show the navigation when menu is visible */
    .navigation.visible {
      display: flex;
    }
  
    .menu-icon {
      display: block;
      cursor: pointer;
      font-size: 24px; /* Adjust size as needed */
    }
  }
  
  /* Hide the menu icon on larger screens */
  @media (min-width: 769px) {
    .menu-icon {
      display: none;
    }
  }