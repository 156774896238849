/* src/components/InfoDiv1/InfoDiv1.css */
.info-div1{
  /* width: 100%; */
  background-image: url("/public/info-div.jpg");
  
  max-width: 100%;
  overflow: hidden;
  padding: 40px 0px 140px 0px;
  display: flex;
  height: 100vh;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  text-align: left;
  background-attachment: fixed;
  /* background-color: #490fb4; */
}

.portfolios-button{
  background-color: var(--rabbitcha-gold);
  min-width: 150px;
  border-radius: 4px;
  padding: 10px;
  color: var(--primary-color);
  border: 2px solid var(--rabbitcha-gold);
  font-size: 20px;
  font-weight: 500;
}

.info-div1 h1{
  font-size: 50px;
}

.info-txt-img{
  opacity: 0; /* Start fully transparent */
  /*transform: translateX(-100%); /* Start off-screen */
  animation: slideInFromLeft 1s forwards; /* Use the defined keyframes */
}

.banner-img-div{
  opacity: 0; /* Start fully transparent */
  /*transform: translateX(-100%); /* Start off-screen */
  animation: slideInFromRight 1s forwards; /* Use the defined keyframes */
}
.banner-image {
  animation: MoveUpDown 2s linear infinite;
  text-align: center;
  width:500px;
}

@keyframes MoveUpDown{
  0% {
    position: relative;
    top: 0;
  }
  50% {
    position: relative;
    top: 10px;
  }
  100% {
    position: relative;
    top: 0;
  }

}



@media (min-width: 768px) { /* Adjust this threshold as needed */
  .info-div1 {
    flex-direction: row; /* Side by side for screens wider than 768px */
    
  }
}

@media (max-width: 768px) { /* Adjust this threshold as needed */
  .info-div1 {
    padding: 60px 20px 140px 15px;
    justify-content: space-evenly;
    background-position: right;
}
  .banner-image{
    width:400px;
  }
}